import React from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Routes } from '../../shared/enums/routes';
import { useHistory } from 'react-router-dom';

export const InitService = observer(() => {
  const history = useHistory();
  history.push(Routes.SelectCity);
  return <Redirect to={Routes.SelectCity}></Redirect>;
});
