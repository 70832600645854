import React from 'react';

import { Box, Stat } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { Layout } from 'antd';

const { Content } = Layout;

type AppTemplateProps = {
  headerTitle: string;
  headerBody?: React.ReactElement;
};

export const AppTemplate: React.FC<AppTemplateProps> = ({ headerTitle, headerBody, children }) => {
  return (
    <Layout style={{ height: '100vh', backgroundImage: `url(/bg.jpg)`, backgroundSize: 'cover', width: '100vw' }}>
      <OuterContainer>
        <Paper>
          <ContentContainer>
            <Box p={5} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {/* <HeadingStyled textAlign='center'>{headerTitle}</HeadingStyled> */}
              {/*  <Image
                preview={false}
                width='95%'
                src='https://mm.artemiymillersockert.club/missis-laser/bikini+armpits.png'
              /> */}
              <StatStyled>{headerBody}</StatStyled>
            </Box>
            {children}
          </ContentContainer>
        </Paper>
      </OuterContainer>
    </Layout>
  );
};

const StatStyled = styled(Stat)`
  margin-top: 8px;
  @media (max-width: 425px) {
    & > dl > dt {
      font-size: var(--chakra-fonts-body);
    }

    & > dl > dd {
      font-size: var(--chakra-fonts-body);
    }
  }

  @media (max-width: 350px) {
    & > dl > dt {
      font-size: var(--chakra-fonts-body);
    }

    & > dl > dd {
      font-size: var(--chakra-fonts-body);
    }
  }
`;

const OuterContainer = styled(Content)`
  height: 100%;
  max-width: 960px;
  max-height: 100vh;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled(Box)`
  height: 99%;
  display: flex;
  flex: 1;
  box-shadow: 7px 4px 14px 2px rgba(34, 60, 80, 0.2);
  background: white;
  border-radius: 28px;
  align-items: center;
  justify-content: center;
`;

const Paper = styled(Box)`
  height: 100%;
  padding: var(--chakra-space-8);

  @media (max-width: 425px) {
    padding: var(--chakra-space-5);
  }
`;
