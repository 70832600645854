import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Input, InputGroup, Stack, Textarea } from '@chakra-ui/react';
import { AppTemplate } from '../../shared/ui/AppTemplate';
import { sendLeadToVakas } from '../../shared/api/auth';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../shared/enums/routes';

const utm_campaign = 'INSTA-LEAD';
const utm_medium = 'INSTA-LEAD';

export const Home = observer(() => {
  const [comment, setComment] = useState<string>('');
  const [promocode, setPromocode] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [username, setUserName] = useState<string>('');
  const history = useHistory();

  const onSubmit = async () => {
    try {
      await sendLeadToVakas({
        comment: comment,
        phone: phone,
        name: username,
        utm_source: window.location.hostname,
        utm_campaign,
        utm_medium,
        promocode,
      }).then(() => history.push(Routes.Success));
    } catch (error) {
      console.log(error);
      history.push(Routes.Error);
    }
  };

  return (
    <AppTemplate headerTitle='Тест драйв'>
      <Box
        p={0}
        overflowY='auto'
        flexGrow={1}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Stack spacing='2rem'>
          <Stack spacing='1rem'>
            <InputGroup>
              <Input
                maxLength={30}
                placeholder='ФИО лида'
                value={username}
                onChange={e => setUserName(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <Input
                maxLength={20}
                placeholder='Номер телефона лида'
                value={phone}
                onChange={event => setPhone(event?.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <Textarea
                maxLength={250}
                placeholder='Комментарий'
                value={comment}
                onChange={e => setComment(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <Input
                maxLength={30}
                placeholder='Промокод'
                value={promocode}
                onChange={e => setPromocode(e.target.value)}
              />
            </InputGroup>
          </Stack>
          <Button
            disabled={phone === ''}
            onClick={onSubmit}
            mt='24px'
            w='100%'
            alignSelf='center'
            colorScheme='teal'
            variant='solid'
          >
            Отправить лид
          </Button>
        </Stack>
      </Box>
    </AppTemplate>
  );
});
